.lm-contents-menu {
  background-color: #efefef;
  min-width: 1190px;
}

.lm-contents-menu_inner {
  margin: 0 auto;
  width: 1190px;
}

.lm-column-block {
  box-sizing: border-box;
  color: #656565;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 10px 20px;
}

.lm-column-block li {
  color: #656565;
  list-style: none;
  margin-bottom: 30px;
  width: 33%;
}

.lm-contents-menu.lm-is-desktop-four-col .lm-column-block li {
  width: 25%;
}

.lm-contents-menu.lm-is-desktop-five-col .lm-column-block li {
  width: 20%;
}

.lm-column-block li h3 {
  font-weight: bold;
  font-family: "SST W20 Bold", Arial, "Helvetica Neue", sans-serif;
  font-size: 80%;
  margin: 1em 0;
}

.lm-menu-column ul {
  padding: 0;
}

.lm-menu-column ul:nth-child(n+1) {
  margin-top: calc(1em + 10px);
}

.lm-menu-column ul li {
  box-sizing: border-box;
  font-size: 70%;
  line-height: 1rem;
  margin-bottom: 8px;
  padding-right: 10px;
  width: 100%;
}

.lm-menu-column ul li.lm-is-lower {
  margin-left: 1.4em;
}

.lm-contents-menu.lm-is-desktop-five-col .lm-menu-column ul li, .lm-contents-menu.lm-is-desktop-four-col .lm-menu-column ul li {
  width: 100%;
}

.lm-menu-column a,
.lm-menu-column a:link,
.lm-menu-column a:visited {
  color: #656565;
  font-family: "SST W20 Roman", Arial, "Helvetica Neue", sans-serif;
  text-decoration: none;
}

.lm-menu-column a:hover {
  color: #333;
  text-decoration: underline;
}

.lm-menu-column, .lm-menu-column a {
  background: #efefef;
}

.lm-contents-menu {
  overflow: hidden;
  transition: height 0.3s ease-out;
}

@media screen and (max-width: 640px) {
  .lm-column-block li, .lm-contents-menu.lm-is-desktop-five-col .lm-column-block li, .lm-contents-menu.lm-is-desktop-four-col .lm-column-block li {
    list-style: none;
    width: 100%;
  }
  .lm-contents-menu_inner {
    width: 100%;
  }
  .lm-contents-menu {
    background-color: #efefef;
    min-width: 100%;
  }
  .lm-column-block li h3 {
    font-weight: bold;
    font-family: "SST W20 Bold", Arial, "Helvetica Neue", sans-serif;
    font-size: 100%;
  }
  .lm-menu-column ul {
    padding: 0;
  }
  .lm-menu-column ul li {
    box-sizing: border-box;
    font-size: 88%;
    line-height: 1.6rem;
    margin-bottom: 5px;
    padding-right: 10px;
    width: 100%;
  }
  .lm-menu-column ul li.lm-is-lower {
    margin-left: 0;
  }
}
@media screen and (min-width: 641px) {
  .lm-contents-menu {
    overflow: visible;
  }
}
.lm-in-list_footmenu {
  margin-top: 8px !important;
}

.lm-in-list_title {
  margin: 0 0 5px 0 !important;
}

.lm-in-list_footmenu {
  margin-top: 0;
  margin-left: 10px;
}

.lm-sitemap-header-container {
  display: none;
}
@media screen and (max-width: 640px) {
  .lm-sitemap-header-container {
    display: block;
  }
}

.lm-sitemap-header {
  display: none;
}
@media screen and (max-width: 640px) {
  .lm-sitemap-header {
    align-items: center;
    background: #efefef;
    border-bottom: 1px solid #bfbfbf;
    box-sizing: border-box;
    display: flex;
    height: 50px;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    text-decoration: none;
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .lm-sitemap-header h2 {
    color: #656565;
    font-size: 1rem;
    margin: 0;
  }
}

@media screen and (max-width: 640px) {
  .lm-sitemap-header .lm-is-open-icon {
    display: block;
    height: 14px;
    width: 14px;
  }
}

@media screen and (max-width: 640px) {
  .lm-sitemap-header .lm-is-close-icon {
    display: none;
    height: 2px;
    width: 14px;
  }
}

@media screen and (max-width: 640px) {
  .lm-sitemap-header.lm-is-active .lm-is-open-icon {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .lm-sitemap-header.lm-is-active .lm-is-close-icon {
    display: block;
  }
}